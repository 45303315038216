import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DataForm from './DataForm';
import ProductSpecificationService from '../services/ProductSpecificationService';

const EditProductSpecification = () => {
  const [specificationData, setSpecificationData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSpecification = async () => {
      const data = await ProductSpecificationService.getProductSpecificationById(id);
      setSpecificationData(data);
    };
    fetchSpecification();
  }, [id]);

  const handleUpdate = async (updatedData) => {
    await ProductSpecificationService.updateProductSpecification(id, updatedData);
    navigate('/product-specifications');
  };

  return <DataForm onSubmit={handleUpdate} initialData={specificationData} />;
};

export default EditProductSpecification;
