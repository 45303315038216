import React from 'react';
import './DataTable.css'; // Import a CSS file for styling
import ProductSpecificationService from '../services/ProductSpecificationService'; 


const SpecificationDataTable = ({ data, refreshData }) => {
  // Function to handle the delete operation
  const handleDelete = async (id) => {
    try {
      await ProductSpecificationService.deleteProductSpecification(id);
      refreshData(); // Refresh the data after deletion
    } catch (error) {
      console.error('Error deleting product specification', error);
      // Handle error (e.g., show an error message)
    }
  };
  return (
    <div className="table-container"> {/* Create a container div */}
      <table className="data-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Brand</th>
          <th>Product Number</th>
          <th>Description</th>
          <th>Last Update</th>
          <th>Lifecycle Status</th>
          <th>Valid For Start</th>
          <th>Valid For End</th>
          <th>Version</th>
          <th>Manufacturer</th>
          <th>Attachment Name</th>
          <th>Attachment Mime Type</th>
          <th>Attachment URL</th>
          <th>Relationship Type</th>
          <th>Relationship Valid For</th>
          <th>Relationship Role</th>
          {/* Add more headers as needed */}
          <th>Actions</th> {/* Header for actions */}
        </tr>
      </thead>
      <tbody>
      {data.length === 0 ? (
            <tr>
              <td colSpan="17">No data available</td>
            </tr>
          ) : (
            data.map(item => (
            <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.name}</td>
            <td>{item.brand}</td>
            <td>{item.productNumber}</td>
            <td>{item.description}</td>
            <td>{item.lastUpdate}</td>
            <td>{item.lifecycleStatus}</td>
            <td>{item.validForStart}</td>
            <td>{item.validForEnd}</td>
            <td>{item.version}</td>
            <td>{item.relatedParties?.[0]?.name ?? 'N/A'}</td> {/* Fallback for related parties */}
            <td>{item.attachments?.[0]?.name ?? 'N/A'}</td> {/* Fallback for attachments */}
            <td>{item.attachments?.[0]?.mimeType ?? 'N/A'}</td>
            <td>{item.attachments?.[0]?.url ?? 'N/A'}</td>
            <td>{item.productSpecificationRelationships?.[0]?.relationshipType ?? 'N/A'}</td> {/* Fallback for relationships */}
            <td>{item.productSpecificationRelationships?.[0]?.validFor ?? 'N/A'}</td>
            <td>{item.productSpecificationRelationships?.[0]?.role ?? 'N/A'}</td>
            {/* Add more data cells as needed */}
            <td>
                <button onClick={() => {/* Handle edit logic */}}>Edit</button>
                <button onClick={() => handleDelete(item.id)}>Delete</button>
            </td>
          </tr>
        ))
      )}
      </tbody>
    </table>
    </div>
  );
};

export default SpecificationDataTable;
