import axios from 'axios';

// Update with the actual URL provided by Google Cloud Run after deploying your Spring Boot microservice
const PRODUCT_OFFERING_API_URL = 'https://catalog.orcadence.com/product-offerings';

const ProductOfferingService = {
  // Get all product offerings
  getAllProductOfferings: async () => {
    try {
      const response = await axios.get(PRODUCT_OFFERING_API_URL);
      return response.data;
    } catch (error) {
      console.error('Error fetching product offerings', error);
      throw error;
    }
  },

  // Get a single product offering by ID
  getProductOfferingById: async (id) => {
    try {
      const response = await axios.get(`${PRODUCT_OFFERING_API_URL}/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching product offering with ID ${id}`, error);
      throw error;
    }
  },

  // Create a new product offering
  createProductOffering: async (productOfferingData) => {
    try {
      const response = await axios.post(PRODUCT_OFFERING_API_URL, productOfferingData);
      return response.data;
    } catch (error) {
      console.error('Error creating a product offering', error);
      throw error;
    }
  },
};

export default ProductOfferingService;
