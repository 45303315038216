import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DataForm from './DataForm';
import ProductOfferingService from '../services/ProductOfferingService';

const EditProductOffering = () => {
  const [offeringData, setOfferingData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOffering = async () => {
      const data = await ProductOfferingService.getProductOfferingById(id);
      setOfferingData(data);
    };
    fetchOffering();
  }, [id]);

  const handleUpdate = async (updatedData) => {
    await ProductOfferingService.updateProductOffering(id, updatedData);
    navigate('/product-offerings');
  };

  return <DataForm onSubmit={handleUpdate} initialData={offeringData} />;
};

export default EditProductOffering;
