import React from 'react';
import '../css/Spinner.css'; 

const Spinner = () => {
  return (
    <div className="spinner">
      <div className="spinner-inner">
        {/* You can add more divs or elements here for more complex spinners */}
      </div>
    </div>
  );
};

export default Spinner;
