import React, { useState, useEffect } from 'react';
import OfferingDataTable from './OfferingDataTable';
import ProductOfferingService from '../services/ProductOfferingService';

const ProductOfferings = () => {
  const [offerings, setOfferings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await ProductOfferingService.getAllProductOfferings();
      console.log(data); // Check the fetched data
      setOfferings(data);
    };
    fetchData();
  }, []);

  return <OfferingDataTable data={offerings} />;
  //return <div>{JSON.stringify(offerings)}</div>;
};

export default ProductOfferings;
