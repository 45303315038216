// component for displaying product specifcations
import React, { useState, useEffect } from 'react';
import SpecificationDataTable from './SpecificationDataTable';
import DataForm from './DataForm'; // Import form component
import ProductSpecificationService from '../services/ProductSpecificationService';
import Spinner from './Spinner'; //  spinner component
import Modal from './Modal.js'; 

const ProductSpecifications = () => {
  const [specifications, setSpecifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false); // State to control the form modal visibility

  useEffect(() => {
    fetchSpecifications();
  }, []);

    const fetchSpecifications = async () => {
      setLoading(true);
      try {
        const data = await ProductSpecificationService.getAllProductSpecifications();
        setSpecifications(data);
      } catch (error) {
        console.error('Error fetching specifications:', error);
      } finally {
        setLoading(false);
      }
    };
  
  if (loading) {
    return <Spinner />;
    }

   // return <SpecificationDataTable data={specifications} />;
    //return <div>{JSON.stringify(specifications)}</div>;
  
  const handleAddNew = () => {
    // Logic to add a new specification
    setShowAddForm(true); // Show the form modal
  };

  const handleFormSubmit = async (newSpecification) => {
    try {
      await ProductSpecificationService.createProductSpecification(newSpecification);
      fetchSpecifications();
      setShowAddForm(false); // Hide the form modal after successful creation
    } catch (error) {
      // Handle submission error
    }
  };

  return (
    <div>
      <button onClick={handleAddNew}>Add New Specification</button>
      {showAddForm && (
        <Modal onClose={() => setShowAddForm(false)}>
          <DataForm onSubmit={handleFormSubmit} />
        </Modal>
      )}
      <SpecificationDataTable data={specifications} refreshData={fetchSpecifications} />
    </div>
  );
};

export default ProductSpecifications;
