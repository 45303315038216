import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import ProductOfferings from './components/ProductOfferings';
import ProductSpecifications from './components/ProductSpecifications';
import EditProductOffering from './components/EditProductOffering';
import EditProductSpecification from './components/EditProductSpecification';
import './App.css'; // Your App's CSS

const App = () => {
  return (
    <Router>
      <div>
        {/* Navigation links (optional) */}
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/product-offerings">Product Offerings</Link>
            </li>
            <li>
              <Link to="/product-specifications">Product Specifications</Link>
            </li>
          </ul>
        </nav>

        {/* Route definitions */}
        <Routes>
        <Route path="/edit-offering/:id" element={<EditProductOffering />} />
          <Route path="/edit-specification/:id" element={<EditProductSpecification />} />
          <Route path="/product-offerings" element={<ProductOfferings />} />
          <Route path="/product-specifications" element={<ProductSpecifications />} />
          <Route path="/" element={<h1>Welcome to the Admin App</h1>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
