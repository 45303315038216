import axios from 'axios';

// Replace with the actual Cloud Run URL of your Spring Boot microservice
const PRODUCT_SPECIFICATION_API_URL = 'https://catalog.orcadence.com/product-specifications';

const ProductSpecificationService = {
  // Get all product specifications
  getAllProductSpecifications: async () => {
    try {
      const response = await axios.get(PRODUCT_SPECIFICATION_API_URL);
      return response.data;
    } catch (error) {
      console.error('Error fetching all product specifications', error);
      throw error;
    }
  },

  // Get a product specification by ID
  getProductSpecificationById: async (id) => {
    try {
      const response = await axios.get(`${PRODUCT_SPECIFICATION_API_URL}/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching product specification with ID ${id}`, error);
      throw error;
    }
  },

  // Create a new product specification
  createProductSpecification: async (productSpecificationData) => {
    try {
      const response = await axios.post(PRODUCT_SPECIFICATION_API_URL, productSpecificationData);
      return response.data;
    } catch (error) {
      console.error('Error creating a product specification', error);
      throw error;
    }
  },

  // Update a product specification
  updateProductSpecification: async (id, productSpecificationData) => {
    try {
      const response = await axios.put(`${PRODUCT_SPECIFICATION_API_URL}/${id}`, productSpecificationData);
      return response.data;
    } catch (error) {
      console.error(`Error updating product specification with ID ${id}`, error);
      throw error;
    }
  },

  // Delete a product specification
  deleteProductSpecification: async (id) => {
    try {
      const response = await axios.delete(`${PRODUCT_SPECIFICATION_API_URL}/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting product specification with ID ${id}`, error);
      throw error;
    }
  },
};

export default ProductSpecificationService;
