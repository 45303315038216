import React from 'react';
import './DataTable.css'; // Import a CSS file for styling

const OfferingDataTable = ({ data }) => {
  return (
    <div className="table-container"> {/* Create a container div */}
      <table className="data-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Categories</th>
            <th>Product Specifications</th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <tr>
              <td colSpan="5">No data available</td>
            </tr>
          ) : (
            data.map(item => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>{item.categories ? item.categories.join(', ') : ''}</td>
                <td>{item.productSpecifications ? item.productSpecifications.join(', ') : ''}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default OfferingDataTable;
